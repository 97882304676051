<template>
    <div style="min-height: 200px">
        <div class="table-responsive">
            <table id="stockLedger" class="table table-bordered">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Txn Type</th>
                        <th>Voucher No</th>
                        <th>Description</th>
                        <th class="text-end">In (Qty)</th>
                        <th class="text-end">Out (Qty)</th>
                        <th class="text-end">Balance (Qty)</th>
                        <th class="text-end">In (Value)</th>
                        <th class="text-end">Out (Value)</th>
                        <th class="text-end">Balance (Value)</th>
                    </tr>
                </thead>
                
                <tbody v-if="balances.length">
                    <tr v-if="showOpening">
                      <td colspan="4" class="text-right text-bold"> Opening Balance</td>
                      <td>  </td>
                      <td>  </td>
                      <td class="text-end"> {{ openingQty ? commaFormat(openingQty) : 0 }} </td>
                      <td>  </td>
                      <td>  </td>
                      <td class="text-end"> {{ openingValue ? commaFormat(openingValue) : 0 }} </td>
                    </tr>
                    <tr v-for="(item, i) in balances" :key="i">
                        <td>{{ item.date }}</td>
                        <td>{{ item.txn_type }}</td>
                        <td>{{ item.voucher_no }}</td>
                        <td>{{ item.description }}</td>
                        <td class="text-end">{{ item.in_quantity ? commaFormat(item.in_quantity) : 0 }}</td>
                        <td class="text-end">{{ item.out_quantity ? commaFormat(item.out_quantity) : 0 }}</td>
                        <td class="text-end">{{ item.balance_stock ? commaFormat(item.balance_stock) : 0 }}</td>
                        <td class="text-end">{{ item.in_amount ? commaFormat(item.in_amount) : 0 }}</td>
                        <td class="text-end">{{ item.out_amount ? commaFormat(item.out_amount) : 0 }}</td>
                        <td class="text-end">{{ item.balance_value ? commaFormat(item.balance_value) : 0 }}</td>
                    </tr>
                    <tr v-if="showClosing">
                      <td colspan="4" class="text-right text-bold"> Closing Balance </td>
                      <td class="text-end"> {{ totalInQty ? commaFormat(totalInQty) : 0 }}</td>
                      <td class="text-end"> {{ totalOutQty ? commaFormat(totalOutQty) : 0 }}  </td>
                      <td class="text-end"> {{ closingQty ? commaFormat(closingQty) : 0 }} </td>
                      <td class="text-end"> {{ totalInAmount ? commaFormat(totalInAmount) : 0 }}</td>
                      <td class="text-end"> {{ totalOutAmount ? commaFormat(totalOutAmount) : 0 }}  </td>
                      <td class="text-end"> {{ closingValue ? commaFormat(closingValue) : 0 }} </td>
                    </tr>
                </tbody>

              <tfoot v-if="!balances.length" class="mt-5 text-center">
                <tr>
                  <td colspan="4" class="text-right text-bold"> Opening Balance</td>
                  <td>  </td>
                  <td>  </td>
                  <td class="text-end"> {{ openingQty ? commaFormat(openingQty) : 0 }} </td>
                  <td>  </td>
                  <td>  </td>
                  <td class="text-end"> {{ openingValue ? commaFormat(openingValue) : 0 }} </td>
                </tr>
                <tr>
                  <td colspan="4" class="text-right text-bold"> Closing Balance </td>
                  <td>  </td>
                  <td>  </td>
                  <td class="text-end"> {{ closingQty ? commaFormat(closingQty) : 0 }} </td>
                  <td>  </td>
                  <td>  </td>
                  <td class="text-end"> {{ closingValue ? commaFormat(closingValue) : 0 }} </td>
                </tr>
              </tfoot>
            </table>

        </div>
    </div>
</template>

<script>
import figureFormatter from '@/services/utils/figureFormatter'
export default {
    name: 'StockLedgerTable',
    props: {
        openingQty: Number,
        totalInQty: Number,
        totalOutQty: Number,
        closingQty: Number,
        openingValue: Number,
        totalInAmount: Number,
        totalOutAmount: Number,
        closingValue: Number,
        balances: {
            default: [],
            type: Array
        },
        showOpening:false,
        showClosing:false
    },
    methods: {
      //
    },
    setup() {
        const {commaFormat} = figureFormatter ()

        return {
            commaFormat
        }
    },
}
</script>

<style scoped>
#stockLedger td{
    padding: 0.72rem 1rem
}
#stockLedger th{
    padding: 10px 3px;
}
</style>
